<template>
  <section class="dtc-main-section" id="">
    <!-- <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件2
    </header> -->

    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      附件2 {{ title }}
    </header>

    <header class="dtc-grid-header8 mt-2">
      <div class="dtc-grid-height">序號</div>
      <div class="dtc-grid-height">培育學校</div>
      <div class="dtc-grid-height">學生人數</div>
      <div class="dtc-grid-height">年級</div>
      <section class="dtc-heaer-s1" style="font-size:12px !important;">
        <div>
          <h6
            style="margin-bottom:22px;border-bottom:1px solid black;font-size:12px !important;padding:4px;"
          >
            經常門
          </h6>
          <h6 style="font-size:12px !important;">
            業務費 (10,000 / 每人每學期)
          </h6>
        </div>
        <div style="transform:translateX(1px);">
          <h6
            style="margin-bottom:22px;border-bottom:1px solid black;font-size:12px !important; padding:4px;"
          >
            資本門
          </h6>
          <h6 style="font-size:12px !important;">
            設備費(15,000 / 每人每學年)
          </h6>
        </div>
        <div style="line-height:100px;font-size:12px !important;">合計</div>
      </section>
      <div class="dtc-grid-height">各校總計</div>
    </header>
    <main v-for="(item, idx) in pageRows" :key="idx">
      <div class="dtc-grid-main2" v-show="idx <= 4">
        <div>{{ item.SerialNo }}</div>
        <div>{{ item.SchoolName }}</div>
        <nav class="dtc-main-sec">
          <div>{{ item.s2 }}</div>
          <div>{{ item.s3 }}</div>
          <div>{{ item.s4 }}</div>
        </nav>
        <nav class="dtc-main-sec">
          <div>{{ levels[1] }}</div>
          <div>{{ levels[2] }}</div>
          <div>{{ levels[3] }}</div>
        </nav>
        <div class="dtc-main-sec">
          <div class="text-right-mode">
            {{ item.L2 ? $formatPrice(item.L2.Service_fee) : 0 }}
          </div>
          <div class="text-right-mode">
            {{ item.L3 ? $formatPrice(item.L3.Service_fee) : 0 }}
          </div>
          <div class="text-right-mode">
            {{ item.L4 ? $formatPrice(item.L4.Service_fee) : 0 }}
          </div>
        </div>
        <div class="dtc-main-sec">
          <div class="text-right-mode">
            {{ item.L2 ? $formatPrice(item.L2.Equipment_costs) : 0 }}
          </div>
          <div class="text-right-mode">
            {{ item.L3 ? $formatPrice(item.L3.Equipment_costs) : 0 }}
          </div>
          <div class="text-right-mode">
            {{ item.L4 ? $formatPrice(item.L4.Equipment_costs) : 0 }}
          </div>
        </div>
        <div class="dtc-main-sec">
          <div class="text-right-mode">
            {{ item.L2 ? $formatPrice(item.L2.SubTotal) : 0 }}
          </div>
          <div class="text-right-mode">
            {{ item.L3 ? $formatPrice(item.L3.SubTotal) : 0 }}
          </div>
          <div class="text-right-mode">
            {{ item.L4 ? $formatPrice(item.L4.SubTotal) : 0 }}
          </div>
        </div>
        <div class="text-right-mode">{{ $formatPrice(item.totalAmount) }}</div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </main>

    <div
      class="d-none d-print-block"
      style="font-size:16px"
      v-if="pageRows.length > 5"
    >
      1/{{ totalPageNum }}
    </div>
    <div v-if="pageRows.length > 5">
      <div class="d-none d-print-block">
        <header
          class="dtc-title titleMargin"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
        >
          {{ title }}
        </header>
      </div>
      <main v-for="(item, idx) in pageRows" :key="idx">
        <div class="dtc-grid-main" v-show="idx > 4 && idx < 10">
          <div>{{ item.SerialNo }}</div>
          <div>{{ item.SchoolName }}</div>
          <nav class="dtc-main-sec">
            <div>{{ item.s2 }}</div>
            <div>{{ item.s3 }}</div>
            <div>{{ item.s4 }}</div>
          </nav>
          <nav class="dtc-main-sec">
            <div>{{ levels[1] }}</div>
            <div>{{ levels[2] }}</div>
            <div>{{ levels[3] }}</div>
          </nav>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Service_fee) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Equipment_costs) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.SubTotal) : 0 }}
            </div>
          </div>
          <div class="text-right-mode">
            {{ $formatPrice(item.totalAmount) }}
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </main>
    </div>

    <div
      class="d-none d-print-block"
      style="font-size:16px"
      v-if="pageRows.length > 10"
    >
      2/{{ totalPageNum }}
    </div>
    <div v-if="pageRows.length > 10">
      <div class="d-none d-print-block">
        <header
          class="dtc-title titleMargin"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
        >
          {{ title }}
        </header>
      </div>
      <main v-for="(item, idx) in pageRows" :key="idx">
        <div class="dtc-grid-main" v-show="idx > 10 && idx < 16">
          <div>{{ item.SerialNo }}</div>
          <div>{{ item.SchoolName }}</div>
          <nav class="dtc-main-sec">
            <div>{{ item.s2 }}</div>
            <div>{{ item.s3 }}</div>
            <div>{{ item.s4 }}</div>
          </nav>
          <nav class="dtc-main-sec">
            <div>{{ levels[1] }}</div>
            <div>{{ levels[2] }}</div>
            <div>{{ levels[3] }}</div>
          </nav>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Service_fee) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Equipment_costs) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.SubTotal) : 0 }}
            </div>
          </div>
          <div class="text-right-mode">
            {{ $formatPrice(item.totalAmount) }}
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </main>
    </div>
    <div
      class="d-none d-print-block"
      style="font-size:16px"
      v-if="pageRows.length > 15"
    >
      3/{{ totalPageNum }}
    </div>
    <div v-if="pageRows.length > 15">
      <div class="d-none d-print-block">
        <header
          class="dtc-title titleMargin"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
        >
          {{ title }}
        </header>
      </div>
      <main v-for="(item, idx) in pageRows" :key="idx">
        <div class="dtc-grid-main" v-show="idx > 15">
          <div>{{ item.SerialNo }}</div>
          <div>{{ item.SchoolName }}</div>
          <nav class="dtc-main-sec">
            <div>{{ item.s2 }}</div>
            <div>{{ item.s3 }}</div>
            <div>{{ item.s4 }}</div>
          </nav>
          <nav class="dtc-main-sec">
            <div>{{ levels[1] }}</div>
            <div>{{ levels[2] }}</div>
            <div>{{ levels[3] }}</div>
          </nav>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Service_fee) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Equipment_costs) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.SubTotal) : 0 }}
            </div>
          </div>
          <div class="text-right-mode">
            {{ $formatPrice(item.totalAmount) }}
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </main>
    </div>
    <div
      class="d-none d-print-block"
      style="font-size:16px"
      v-if="pageRows.length > 20"
    >
      4/{{ totalPageNum }}
    </div>
    <div v-if="pageRows.length > 20">
      <div class="d-none d-print-block">
        <header
          class="dtc-title titleMargin"
          style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
        >
          {{ title }}
        </header>
      </div>
      <main v-for="(item, idx) in pageRows" :key="idx">
        <div class="dtc-grid-main" v-show="idx > 20">
          <div>{{ item.SerialNo }}</div>
          <div>{{ item.SchoolName }}</div>
          <nav class="dtc-main-sec">
            <div>{{ item.s2 }}</div>
            <div>{{ item.s3 }}</div>
            <div>{{ item.s4 }}</div>
          </nav>
          <nav class="dtc-main-sec">
            <div>{{ levels[1] }}</div>
            <div>{{ levels[2] }}</div>
            <div>{{ levels[3] }}</div>
          </nav>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Service_fee) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Service_fee) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.Equipment_costs) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.Equipment_costs) : 0 }}
            </div>
          </div>
          <div class="dtc-main-sec">
            <div class="text-right-mode">
              {{ item.L2 ? $formatPrice(item.L2.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L3 ? $formatPrice(item.L3.SubTotal) : 0 }}
            </div>
            <div class="text-right-mode">
              {{ item.L4 ? $formatPrice(item.L4.SubTotal) : 0 }}
            </div>
          </div>
          <div class="text-right-mode">
            {{ $formatPrice(item.totalAmount) }}
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </main>
    </div>
    <!-- <footer class="dtc-note">
      <div>備註</div>
      <div>1.一年級係指107學年入學公費生。</div>
      <div>2.二年級係指106學年度入學公費生。</div>
      <div>3.三年級係指105學年度入學公費生。</div>
      <div>4.四年級係指104學年度入學公費生</div>
      <div>
        5.國立成功大學、國立陽明大學、中國醫藥大學、義守大學、國立台中科技大學及慈濟科技大學104學年無招生學生。
      </div>
      <div>6.國立陽明大學、中國醫藥大學及義守大學105學年度無招生學生。</div>
      <div>
        7.國立成功大學、義守大學、國立台北護理健康大學、長庚科技大學及弘光科技大學106學年度無招生學生。
      </div>
    </footer> -->
    <div class="d-none d-print-block" style="font-size:16px">
      {{ totalPageNum }}/{{ totalPageNum }}
    </div>
  </section>
</template>

<script>
import queryString from "query-string";
const schools = [
  "國立臺灣大學",
  "國立臺灣大學",
  "國立陽明大學",
  "國立金門大學",
  "臺北醫學大學",
  "馬偕醫學院",
  "輔仁大學",
  "亞洲大學",
  "中國醫藥大學",
  "中山醫學大學",
  "大葉大學",
  "長榮大學",
  "義守大學",
  "高雄醫學大學",
  "慈濟大學",
  "國立臺北健康護理大學",
  "國立台中科技大學",
  "長庚科技大學",
  "中臺科技大學",
  "弘光科技大學",
  "輔英科技大學",
  "慈濟科技大學",
];
const levels = ["一", "二", "三", "四"];
const rowObj = {};

export default {
  name: "doc2",
  data() {
    return {
      twYears,
      term: 1,
      schools,
      levels,
      pageRows: [],
      pageSize: 22,
      isHeaderVisible: true,
      bYear: "108",
      map: [],
      schoolArr: [],
      title: "",
      totalPageNum: 0,
    };
  },

  methods: {
    async getData(reset) {
      reset ? (this.pageRows = []) : "";
      const url = `Budgeting/GetOftenDetailPri?schoolcode=&byear=${this.bYear}&term=${this.term}`;
      try {
        const map = await window.axios.get(url);
        if (map) {
          this.map = map[0].budgeting_head;
          this.title = map[0].Title;
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
      if (!this.schoolArr.length)
        this.schoolArr = await window.axios.get("School/GetAll");
      this.map.forEach((s, i) => {
        const { SchoolName } = this.schoolArr.find(
          (b) => b.SchoolCode == s.SchoolCode
        );
        this.map[i].SchoolName = SchoolName;
        s.s2 = s.s3 = s.s4 = s.s1 = 0;
        this.map[i].totalAmount = 0;
        // item.SerialNo = s.SerialNo;
        s.often_detail.forEach((k) => {
          k.Grade == 1 ? (s.s1 = k.NumberOfPeople) : "";
          k.Grade == 2 ? (s.s2 = k.NumberOfPeople) : "";
          k.Grade == 3 ? (s.s3 = k.NumberOfPeople) : "";
          k.Grade == 4 ? (s.s4 = k.NumberOfPeople) : "";
          if (k.Grade == 2) {
            this.map[i].L2 = { ...k };
          } else if (k.Grade == 3) {
            this.map[i].L3 = { ...k };
          } else if (k.Grade == 4) {
            this.map[i].L4 = { ...k };
          }
          this.map[i].totalAmount += k.SubTotal;
          // item.totalAmount += k.Subtotal;
        });
      });
      this.pageRows = [...this.map];

      this.pageRows.sort(function(a, b) {
        return a.SerialNo - b.SerialNo;
      });

      this.totalPageNum = Math.ceil(this.pageRows.length / 5);
    },
  },
  async mounted() {
    const {
      schoolcode: SchoolCode,
      byear: Byear = "109",
      term = "1",
      category: Category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    Byear ? (this.bYear = Byear) : (this.bYear = "108");

    //for check is same school-prevent see other school
    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != SchoolCode) {
        return;
      }
    }
    //check Can see and then get data

    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-l-100 {
  line-height: 100px !important;
}
.dtc-col2 {
  display: grid;
  grid-template-rows: 40px 1fr;
  > div:last-child {
    line-height: 60px !important;
  }
  > div:first-child {
    border-bottom: 1px solid var(--dtc-border-color);
    line-height: 40px;
  }
}
.dtc-main-section {
  position: relative;
  --dtc-grid-header-height: 150px;
  --dtc-doc8-width: 889px;
  --dtc-border-color: var(--dark);
  margin: 30px auto;
  min-height: 120px;
  max-width: var(--dtc-doc8-width);
  font-size: 12px;
  text-align: center;
}
.dtc-grid-height {
  line-height: var(--dtc-grid-header-height);
}

.dtc-grid-header8 {
  position: sticky;
  background: white;
  // background: var(--dark);
  top: 50px;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  grid-template-columns: 36px 211px 61px 40px 450px 90px;
  > div {
    height: var(--dtc-grid-header-height);
    border-right: 1px solid var(--dtc-border-color);
    text-align: center;
  }
  section {
    display: grid;
    position: relative;
    align-items: end;
    border-right: 1px solid var(--dtc-border-color);
    &::before,
    &::after {
      position: absolute;
      content: "實報實銷類";
      display: block;
      transform: translateX(-50%);
      width: max-content;
      height: 30px;
      font-size: 16px;
      top: 10%;
      left: 50%;
      right: 0;
      bottom: 0;
    }
    > div {
      max-height: 100px;
      padding-top: 2px;
      height: var(--dtc-grid-header-height);
      border-right: 1px solid var(--dtc-border-color);
      border-top: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-right: none;
    }
  }
  .dtc-heaer-s1 {
    grid-template-columns: repeat(3, 1fr);
    padding-left: 1px;
    &::before {
      content: "各校培育偏鄉護理菁英計畫公費生經費";
    }
    &::after {
      content: "";
    }
  }
  .dtc-heaer-s2 {
    &::before {
      content: "定額核銷類";
    }
    &::after {
      content: "";
    }
    grid-template-columns: repeat(6, 1fr);
  }
  .dtc-heaer-s3 {
    &::before {
      content: "在補助額度內檢";
      top: 2%;
    }
    &::after {
      content: "附單據或證明類";
      top: 15%;
    }
    grid-template-columns: repeat(2, 1fr);
  }
}

.dtc-note {
  grid-column: 1/-1;
  text-align: left;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: 168px;
  grid-template-columns: 1fr;
  padding: 7px;
  > div:not(:first-child) {
    padding-left: 9px;
  }
}
.dtc-grid-main2,
.dtc-grid-main {
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  --height: 84px;
  height: var(--height);
  // grid-template-columns: 36px 132px 51px 40px 360px 450px 180px 90px;
  grid-template-columns: 36px 211px 61px 40px repeat(3, 150px) 90px;
  font-size: 16px;
  > div {
    border-right: 1px solid var(--dtc-border-color);
    line-height: var(--height);
  }
  > div:last-child {
    border-right: none;
  }
  .dtc-main-sec {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    border-right: 1px solid var(--dtc-border-color);
    > div {
      height: 28px;
      line-height: 29px;
      border-bottom: 1px solid var(--dtc-border-color);
    }
    > div:last-child {
      border-bottom: 0;
    }
  }
}
.dtc-title {
  display: inline-block;
  margin: auto;
  font-size: 16px;
  width: 709px;
}

.input-group-prepend .input-group-text {
  min-width: 150px;
}

@media print {
  #app .input-group-prepend .input-group-text {
    background: white;
    color: black;
  }
  #app .input-group-append .input-group-text {
    background: white;
    color: black;
  }
  #app {
    .input-group input,
    .input-group-text input,
    input,
    textarea,
    select,
    textarea:focus .input-group select {
      background: white;
      color: black;
    }
  }
}
.text-right-mode {
  text-align: right;
  padding-right: 5px;
}

@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-main-section {
    --dtc-border-color: var(--dark) !important;
    width: var(--dtc-doc8-width);
    position: relative;
    //margin: 3px auto 60px auto;
    width: var(--dtc-doc8-width);
    color: black !important;
  }

  .dtc-grid-main {
    border-top: 1px solid var(--dtc-border-color);
  }

  .titleMargin {
    margin-top: 50vh;
  }
}
</style>
